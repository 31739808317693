export const state = {
  APPLICATIONS: "applications",
  ACCOUNTS: "accounts",
  CLUBS: "clubs",
  EVENTS: "events",
  EVENT_CREATE: "event-create",
  LIVE_EVENTS: "live-events",
  MONITORING: "monitoring",
  SYSTEMS: "systems",
  TEMPLATES: "templates",
  TENANTS: "tenants",
  USERS: "users",
  INVENTORY: "inventory",
  PACKAGES: "packages",
  INSTALLERS: "installers",
  TEAMS: "teams",
  ANNOUNCES: "announces",
  CATEGORIES: "categories",
  TOOLS: "tools",
  DEEP_LINKS: "deep-links",
  IMAGE_GALLERY: "image-gallery",
  PRE_ROLLS: "pre-rolls",
  ABOUT: "about",
  ACCOUNT_SETTINGS: "account-settings",
  PINNED_SYSTEMS: "pinned-systems",
  SUBSCRIPTION: "subscription",
};

export const stateRefs = {
  event: state.EVENTS,
  system: state.SYSTEMS,
  category: state.CATEGORIES,
  club: state.CLUBS,
  user: state.USERS,
  team: state.TEAMS,
  tool: state.TOOLS,
  announce: state.ANNOUNCES,
  application: state.APPLICATIONS,
  account: state.ACCOUNTS,
  about: state.ABOUT,
  accountSettings: state.ACCOUNT_SETTINGS,
  pinnedSystems: state.PINNED_SYSTEMS,
  upgradesSystems: state.UPGRADES_SYSTEMS,
  subscription: state.SUBSCRIPTION,
};

export const service = {
  ANALYTICS: "analytics",
};

export const stateParams = {
  LIMIT: "limit",
  OFFSET: "offset",
};

export const bulkActions = {
  EDIT: "edit",
  DELETE: "delete",
  UPGRADE: "upgrade",
  REMOVE_FROM_CLUB: "remove from club",
  APPROVE: "approve",
  TEMP_APPROVE: "temp approve",
  REJECT: "reject",
  CLEAR_ALL: "clear all table",
  RUN_SYSTEM_TEST: "run system test",
  CREATE_TEST_EVENT: "create test event",
  SET_TARGET_VERSION: "set target version",
  INSTALL_PACKAGE: "install package",
  CONVERT_TO_PLAY: "convert to Play",
};

export const clubStreamTypeFilterOption = [
  { id: "wowza", title: "RTMP Push" },
  { id: "zixi", title: "Reliable UDP" },
];

export const KICKOFF_TIMES = [
  { id: 15, name: "15 minutes" },
  { id: 30, name: "30 minutes" },
  { id: 45, name: "45 minutes" },
  { id: 60, name: "60 minutes" },
  { id: 90, name: "90 minutes" },
  { id: 120, name: "120 minutes" },
];

export const CLUB_STATUS_HIDDEN = "hidden";
export const CLUB_STATUS_ACTIVE = "active";
export const CLUB_STATUS_DISABLED = "disabled";
export const CLUB_STATUS_EXЕTERNAL_SCHEDULING = "externalScheduling";

export const clubStatusFilterOptions = [
  { id: CLUB_STATUS_ACTIVE, title: "Active" },
  { id: CLUB_STATUS_HIDDEN, title: "Hidden from search" },
];

export const COMMERCE_TYPE_MEMBERS = "members";
export const COMMERCE_TYPE_FREE = "free";
export const COMMERCE_TYPE_PREMIUM = "premium";

export const categoryTypes = {
  MAIN: "main",
  SUB: "sub",
};

export const AUTO_PRODUCTION_AUTO = "auto-camera";
export const AUTO_PRODUCTION_TACTICAL = "tactical";
export const AUTO_PRODUCTION_LEFT = "leftside";
export const AUTO_PRODUCTION_RIGHT = "rightside";
export const AUTO_PRODUCTION_CENTER = "center";

export const SPORT_TYPES = [
  {
    id: "soccer",
    name: "Soccer",
    autoProductionOptions: [
      AUTO_PRODUCTION_AUTO,
      AUTO_PRODUCTION_TACTICAL,
      AUTO_PRODUCTION_LEFT,
      AUTO_PRODUCTION_RIGHT,
      AUTO_PRODUCTION_CENTER,
    ],
    playerPositionOptions: [
      { key: "gk", name: "Goalkeeper" },
      { key: "df", name: "Defender" },
      { key: "fb", name: "Fullback" },
      { key: "cb", name: "Center Back" },
      { key: "wb", name: "Wing Back" },
      { key: "mf", name: "Midfielder" },
      { key: "dm", name: "Defensive Midfielder" },
      { key: "cm", name: "Central Midfielder" },
      { key: "am", name: "Attacking Midfielder" },
      { key: "wg", name: "Winger" },
      { key: "fw", name: "Forward" },
      { key: "st", name: "Striker" },
      { key: "lw", name: "Left Wing" },
      { key: "rw", name: "Right Wing" },
      { key: "cf", name: "Centre Forward" },
      { key: "ss", name: "Second Striker" },
      { key: "sw", name: "Sweeper" },
      { key: "lb", name: "Left Back" },
      { key: "rb", name: "Right Back" },
      { key: "lwb", name: "Left Wing Back" },
      { key: "rwb", name: "Right Wing Back" },
      { key: "lm", name: "Left Midfielder" },
      { key: "rm", name: "Right Midfielder" },
      { key: "pl", name: "Player" },
    ],
  },
  {
    id: "basketball",
    name: "Basketball",
    autoProductionOptions: [
      AUTO_PRODUCTION_AUTO,
      AUTO_PRODUCTION_TACTICAL,
      AUTO_PRODUCTION_LEFT,
      AUTO_PRODUCTION_RIGHT,
      AUTO_PRODUCTION_CENTER,
    ],
    playerPositionOptions: [
      { key: "pg", name: "Point Guard" },
      { key: "sg", name: "Shooting Guard" },
      { key: "sf", name: "Small Forward" },
      { key: "pf", name: "Power Forward" },
      { key: "c", name: "Center" },
      { key: "pl", name: "Player" },
    ],
  },
  {
    id: "american_football",
    name: "American Football",
    autoProductionOptions: [
      AUTO_PRODUCTION_AUTO,
      AUTO_PRODUCTION_LEFT,
      AUTO_PRODUCTION_RIGHT,
      AUTO_PRODUCTION_CENTER,
    ],
    playerPositionOptions: [
      { key: "qb", name: "Quarterback" },
      { key: "rb", name: "Running Back" },
      { key: "fb", name: "Fullback" },
      { key: "wr", name: "Wide Receiver" },
      { key: "te", name: "Tight End" },
      { key: "ot", name: "Offensive Tackle" },
      { key: "og", name: "Offensive Guard" },
      { key: "c", name: "Center" },
      { key: "dt", name: "Defensive Tackle" },
      { key: "de", name: "Defensive End" },
      { key: "lb", name: "Linebacker" },
      { key: "cb", name: "Cornerback" },
      { key: "s", name: "Safety" },
      { key: "k", name: "Kicker" },
      { key: "p", name: "Punter" },
      { key: "pl", name: "Player" },
    ],
  },
  {
    id: "flag-football",
    name: "Flag Football",
    autoProductionOptions: [
      AUTO_PRODUCTION_AUTO,
      AUTO_PRODUCTION_LEFT,
      AUTO_PRODUCTION_RIGHT,
      AUTO_PRODUCTION_CENTER,
    ],
    playerPositionOptions: [
      { key: "qb", name: "Quarterback" },
      { key: "rb", name: "Running Back" },
      { key: "fb", name: "Fullback" },
      { key: "wr", name: "Wide Receiver" },
      { key: "te", name: "Tight End" },
      { key: "ot", name: "Offensive Tackle" },
      { key: "og", name: "Offensive Guard" },
      { key: "c", name: "Center" },
      { key: "dt", name: "Defensive Tackle" },
      { key: "de", name: "Defensive End" },
      { key: "lb", name: "Linebacker" },
      { key: "cb", name: "Cornerback" },
      { key: "s", name: "Safety" },
      { key: "k", name: "Kicker" },
      { key: "p", name: "Punter" },
      { key: "pl", name: "Player" },
    ],
  },
  {
    id: "rugby",
    name: "Rugby",
    autoProductionOptions: [
      AUTO_PRODUCTION_AUTO,
      AUTO_PRODUCTION_LEFT,
      AUTO_PRODUCTION_RIGHT,
      AUTO_PRODUCTION_CENTER,
    ],
    playerPositionOptions: [
      { key: "pr", name: "Prop" },
      { key: "hk", name: "Hooker" },
      { key: "lk", name: "Lock" },
      { key: "fl", name: "Flanker" },
      { key: "no8", name: "Number Eight" },
      { key: "sh", name: "Scrum-Half" },
      { key: "fh", name: "Fly-Half" },
      { key: "ct", name: "Center" },
      { key: "wg", name: "Wing" },
      { key: "fb", name: "Fullback" },
      { key: "ic", name: "Inside Centre" },
      { key: "oc", name: "Outside Centre" },
      { key: "lw", name: "Left Wing" },
      { key: "rw", name: "Right Wing" },
      { key: "sr", name: "Second Row" },
      { key: "osf", name: "Openside Flanker" },
      { key: "bsf", name: "Blindside Flanker" },
      { key: "lhp", name: "Loosehead Prop" },
      { key: "thp", name: "Tighthead Prop" },
      { key: "pl", name: "Player" },
    ],
  },
  {
    id: "hockey",
    name: "Ice Hockey",
    autoProductionOptions: [
      AUTO_PRODUCTION_AUTO,
      AUTO_PRODUCTION_LEFT,
      AUTO_PRODUCTION_RIGHT,
      AUTO_PRODUCTION_CENTER,
    ],
    playerPositionOptions: [
      { key: "g", name: "Goalie" },
      { key: "d", name: "Defenseman" },
      { key: "ld", name: "Left Defenseman" },
      { key: "rd", name: "Right Defenseman" },
      { key: "c", name: "Center" },
      { key: "lw", name: "Left Wing" },
      { key: "rw", name: "Right Wing" },
      { key: "gt", name: "Goaltender" },
      { key: "pl", name: "Player" },
    ],
  },
  {
    id: "fieldhockey",
    name: "Field Hockey",
    autoProductionOptions: [
      AUTO_PRODUCTION_AUTO,
      AUTO_PRODUCTION_LEFT,
      AUTO_PRODUCTION_RIGHT,
      AUTO_PRODUCTION_CENTER,
    ],
    playerPositionOptions: [
      { key: "gk", name: "Goalkeeper" },
      { key: "df", name: "Defender" },
      { key: "mf", name: "Midfielder" },
      { key: "fw", name: "Forward" },
      { key: "ld", name: "Left Defense" },
      { key: "cd", name: "Centre Defense" },
      { key: "rd", name: "Right Defense" },
      { key: "lm", name: "Left Midfielder" },
      { key: "cm", name: "Centre Midfielder" },
      { key: "rm", name: "Right Midfielder" },
      { key: "lw", name: "Left Wing" },
      { key: "cf", name: "Centre Forward" },
      { key: "rw", name: "Right Wing" },
      { key: "pl", name: "Player" },
    ],
  },
  {
    id: "rollerhockey",
    name: "Roller Hockey",
    autoProductionOptions: [
      AUTO_PRODUCTION_AUTO,
      AUTO_PRODUCTION_TACTICAL,
      AUTO_PRODUCTION_LEFT,
      AUTO_PRODUCTION_RIGHT,
      AUTO_PRODUCTION_CENTER,
    ],
    playerPositionOptions: [
      { key: "gk", name: "Goalkeeper" },
      { key: "df", name: "Defender" },
      { key: "mf", name: "Midfielder" },
      { key: "fw", name: "Forward" },
      { key: "gt", name: "Goaltender" },
      { key: "ld", name: "Left Defenseman" },
      { key: "rd", name: "Right Defenseman" },
      { key: "c", name: "Center" },
      { key: "lw", name: "Left Wing" },
      { key: "rw", name: "Right Wing" },
      { key: "pl", name: "Player" },
    ],
  },
  {
    id: "futsal",
    name: "Futsal",
    autoProductionOptions: [
      AUTO_PRODUCTION_AUTO,
      AUTO_PRODUCTION_TACTICAL,
      AUTO_PRODUCTION_LEFT,
      AUTO_PRODUCTION_RIGHT,
      AUTO_PRODUCTION_CENTER,
    ],
    playerPositionOptions: [
      { key: "gk", name: "Goalkeeper" },
      { key: "df", name: "Defender" },
      { key: "wg", name: "Wing" },
      { key: "pv", name: "Pivot" },
      { key: "pl", name: "Player" },
    ],
  },
  {
    id: "handball",
    name: "Handball",
    autoProductionOptions: [
      AUTO_PRODUCTION_AUTO,
      AUTO_PRODUCTION_TACTICAL,
      AUTO_PRODUCTION_LEFT,
      AUTO_PRODUCTION_RIGHT,
      AUTO_PRODUCTION_CENTER,
    ],
    playerPositionOptions: [
      { key: "gk", name: "Goalkeeper" },
      { key: "lw", name: "Left Wing" },
      { key: "rw", name: "Right Wing" },
      { key: "pv", name: "Pivot" },
      { key: "lb", name: "Left Back" },
      { key: "cb", name: "Center Back" },
      { key: "rb", name: "Right Back" },
      { key: "pl", name: "Player" },
    ],
  },
  {
    id: "volleyball",
    name: "Volleyball",
    autoProductionOptions: [
      AUTO_PRODUCTION_AUTO,
      AUTO_PRODUCTION_LEFT,
      AUTO_PRODUCTION_RIGHT,
      AUTO_PRODUCTION_CENTER,
    ],
    playerPositionOptions: [
      { key: "oh", name: "Outside Hitter" },
      { key: "op", name: "Opposite Hitter" },
      { key: "st", name: "Setter" },
      { key: "mb", name: "Middle Blocker" },
      { key: "lb", name: "Libero" },
      { key: "ds", name: "Defensive Specialist" },
      { key: "pl", name: "Player" },
    ],
  },
  {
    id: "wrestling",
    name: "Wrestling",
    playerPositionOptions: [{ key: "pl", name: "Player" }],
  },
  {
    id: "gymnastics",
    name: "Gymnastics",
    playerPositionOptions: [{ key: "pl", name: "Player" }],
  },
  {
    id: "beachvolleyball",
    name: "Beach Volleyball",
    autoProductionOptions: [AUTO_PRODUCTION_AUTO],
    playerPositionOptions: [
      { key: "bl", name: "Blocker" },
      { key: "df", name: "Defender" },
      { key: "pl", name: "Player" },
    ],
  },
  {
    id: "lacrosse",
    name: "Lacrosse",
    autoProductionOptions: [AUTO_PRODUCTION_AUTO],
    playerPositionOptions: [
      { key: "atk", name: "Attack" },
      { key: "mf", name: "Midfield" },
      { key: "df", name: "Defense" },
      { key: "gk", name: "Goalkeeper" },
      { key: "pl", name: "Player" },
    ],
  },
  {
    id: "baseball",
    name: "Baseball",
    autoProductionOptions: [AUTO_PRODUCTION_AUTO],
    playerPositionOptions: [
      { key: "p", name: "Pitcher" },
      { key: "c", name: "Catcher" },
      { key: "1b", name: "First Baseman" },
      { key: "2b", name: "Second Baseman" },
      { key: "3b", name: "Third Baseman" },
      { key: "ss", name: "Shortstop" },
      { key: "lf", name: "Left Fielder" },
      { key: "cf", name: "Center Fielder" },
      { key: "rf", name: "Right Fielder" },
      { key: "pl", name: "Player" },
    ],
  },
  {
    id: "snooker",
    name: "Snooker",
    autoProductionOptions: [AUTO_PRODUCTION_AUTO],
    playerPositionOptions: [{ key: "pl", name: "Player" }],
  },
  {
    id: "softball",
    name: "Softball",
    autoProductionOptions: [AUTO_PRODUCTION_AUTO],
    playerPositionOptions: [
      { key: "p", name: "Pitcher" },
      { key: "c", name: "Catcher" },
      { key: "1b", name: "First Baseman" },
      { key: "2b", name: "Second Baseman" },
      { key: "3b", name: "Third Baseman" },
      { key: "ss", name: "Shortstop" },
      { key: "lf", name: "Left Fielder" },
      { key: "cf", name: "Center Fielder" },
      { key: "rf", name: "Right Fielder" },
      { key: "pl", name: "Player" },
    ],
  },
  {
    id: "floorball",
    name: "Floorball",
    autoProductionOptions: [AUTO_PRODUCTION_AUTO],
    playerPositionOptions: [
      { key: "gk", name: "Goalkeeper" },
      { key: "df", name: "Defender" },
      { key: "mf", name: "Midfielder" },
      { key: "fw", name: "Forward" },
      { key: "pl", name: "Player" },
    ],
  },
  {
    id: "cricket",
    name: "Cricket",
    playerPositionOptions: [
      { key: "bt", name: "Batsman" },
      { key: "bw", name: "Bowler" },
      { key: "wk", name: "Wicket-Keeper" },
      { key: "ar", name: "All-Rounder" },
      { key: "pl", name: "Player" },
    ],
  },
  {
    id: "waterpolo",
    name: "Waterpolo",
    playerPositionOptions: [
      { key: "gk", name: "Goalkeeper" },
      { key: "cf", name: "Center Forward" },
      { key: "cb", name: "Center Back" },
      { key: "wg", name: "Wing" },
      { key: "dr", name: "Driver" },
      { key: "pt", name: "Point" },
      { key: "cd", name: "Centre Defense" },
      { key: "lw", name: "Left Wing" },
      { key: "rw", name: "Right Wing" },
      { key: "pl", name: "Player" },
    ],
  },
  {
    id: "netball",
    name: "Netball",
    playerPositionOptions: [
      { key: "gk", name: "Goalkeeper" },
      { key: "gd", name: "Goal Defence" },
      { key: "wd", name: "Wing Defence" },
      { key: "c", name: "Centre" },
      { key: "wa", name: "Wing Attack" },
      { key: "ga", name: "Goal Attack" },
      { key: "gs", name: "Goal Shooter" },
      { key: "pl", name: "Player" },
    ],
  },
  {
    id: "badminton",
    name: "Badminton (fixed frame)",
    playerPositionOptions: [{ key: "pl", name: "Player" }],
  },
  {
    id: "bowling",
    name: "Bowling (fixed frame)",
    playerPositionOptions: [{ key: "pl", name: "Player" }],
  },
  {
    id: "swimming",
    name: "Swimming (fixed frame)",
    playerPositionOptions: [{ key: "pl", name: "Player" }],
  },
  {
    id: "tennis",
    name: "Tennis (fixed frame)",
    playerPositionOptions: [{ key: "pl", name: "Player" }],
  },
  {
    id: "other",
    name: "Other",
    playerPositionOptions: [{ key: "pl", name: "Player" }],
  },
];
export const GAME_TYPE_GAME = "game";
export const GAME_TYPE_TRAINING = "training";
export const GAME_TYPE_OTHER = "other";

export const GAME_TYPES = [
  { id: GAME_TYPE_GAME, name: "Game" },
  { id: GAME_TYPE_TRAINING, name: "Practice" },
  { id: GAME_TYPE_OTHER, name: "Other" },
];

export const POSTERS_TARGET_OPTIONS = [
  { id: "None", name: "None" },
  { id: "New window", name: "New window" },
  { id: "Redirect", name: "Redirect" },
];

export const root = {
  state,
  stateRefs,
  stateParams,
  stateParamValue: {
    [stateParams.LIMIT]: 10,
    [stateParams.OFFSET]: 0,
  },
  grid: {
    itemsPerPage: [
      { id: 3, name: 3 },
      { id: 6, name: 6 },
      { id: 9, name: 9 },
    ],
    rotateOptions: [
      { id: 30, name: 30 },
      { id: 60, name: 60 },
      { id: 90, name: 90 },
    ],
  },
  pagination: {
    itemsPerPage: [
      { id: 10, name: 10 },
      { id: 25, name: 25 },
      { id: 50, name: 50 },
      { id: 100, name: 100 },
    ],
    actions: {
      FIRST: "first",
      NEXT: "next",
      PREV: "prev",
    },
    DEFAULT_COUNT: 10,
  },
  team: {
    defaultColor: "#FF0000",
  },
  teams: {
    maxLoad: 100,
    types: {
      ARCHIVED: "archived",
    },
  },
  resolution: {
    logo: {
      small: "small",
      medium: "medium",
      big: "big",
      large: "large",
    },
  },
  role: {
    ADMIN: "admin",
    SUPERADMIN: "superadmin",
    ACCOUNTADMIN: "accountadmin",
    USER: "user",
    DEMOUSER: "demouser",
    BILLINGADMIN: "billingadmin",
  },
  GENIUS_USER_EMAIL: "genius-inetegration-service@pixellot.tv",
  bulkActions,
  bulkActionsItemsLimit: {
    [bulkActions.EDIT]: 25,
    [bulkActions.DELETE]: 25,
    [bulkActions.CONVERT_TO_PLAY]: 25,
  },
  dispatchActions: {
    BULK_SELECTION: "bulkSelection",
    SELECTION_CHANGE: "selectionChange",
  },
  bulkStatuses: {
    failed: "Failed",
    updated: "Updated",
    deleted: "Deleted",
  },
  selectActions: {
    SELECT_ALL: "selectAll",
    SELECT_ALL_PAGES: "selectAllPages",
    CLEAR_SELECTION: "clearSelection",
  },
  TENANT_LOAD_LIMIT: 10,
  filterActions: {
    UPDATE: "update",
  },
  GENERAL_URL_REGEX: "(https?://)([a-z0-9]+)([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(/.*)?$",
  GENERAL_STREAM_REGEX:
    /^(rtmp|rtmps):\/\/[a-z0-9A-Z.-]*(:[0-9]*)?\/.*|rtmp(-limelight)?:\/\/[a-z0-9A-Z.-]*(:[0-9]*)?\/.*/,
  YOUTUBE_STREAM_REGEX: /^rtmp:\/\/.*youtube\.com\/[a-z0-9]+$/,
  FACEBOOK_STREAM_REGEX: /^rtmps:\/\/.*facebook\.com:443\/[a-z0-9]+\/?$/,
  bettingSettings: [
    { id: "1080p", name: "1080p", bitrate: [3000, 6000] },
    { id: "720p", name: "720p", bitrate: [1500, 3000] },
    { id: "480p", name: "480p", bitrate: [750, 1500] },
    { id: "360p", name: "360p", bitrate: [500, 1000] },
  ],
  BETTING_FORWARDING_TYPES: {
    TRANSCODED: "Transcoded",
    NATIVE: "Native",
    NONE: "None",
  },
  CLUB_STATUSES_DROPDOWN_FILTER: [CLUB_STATUS_HIDDEN, CLUB_STATUS_ACTIVE],
  ALLOWED_BREAKDOWNS_SPORTTYPES: [
    { id: "soccer", name: "soccer" },
    { id: "basketball", name: "basketball" },
  ],
  SOCIAL_MEDIA_LINK_TYPES: [
    { name: "facebook", title: "Facebook", iconClass: "fa-facebook-square" },
    { name: "twitter", title: "Twitter", iconClass: "fa-twitter-square" },
    { name: "instagram", title: "Instagram", iconClass: "fa-instagram" },
    { name: "web", title: "Web", iconClass: "fa-external-link-square" },
  ],
  VAS_TYPES: {
    OVERLAY_PROVIDER: "overlayProvider",
    VIDEO_ENHANCER: "videoEnhancer",
    RAW_DATA_CONSUMER: "rawDataConsumer",
    HLS_SERVER: "hlsServer",
  },
  PLUGIN_TYPE: {
    DUAL_PRODUCTION: "dualProduction",
    GAME_BREAKDOWN: "gameBreakdown",
    MULTI_STREAM: "multiStream",
    GAME_BREAKDOWN_FILTER: "gameBreakdownFilter",
    MIXER_HIGHLIGHTS_TYPE: "highlightsType",
    PANO_DISABLER: "panoDisabler",
  },
};

export const monitoring = {
  [state.MONITORING]: {
    stateParamValue: {
      [stateParams.LIMIT]: 25,
    },
  },
};

export const users = {
  [state.USERS]: {
    bulkActions: {
      joined: [bulkActions.REMOVE_FROM_CLUB],
      pending: [bulkActions.APPROVE, bulkActions.TEMP_APPROVE, bulkActions.REJECT],
    },
    invalidData: {
      billingadmin: ["tenants", "clubs", "permissions"],
      superadmin: ["tenants", "clubs", "permissions"],
      accountadmin: ["clubs", "permissions", "isActive"],
      user: ["tenants", "isActive"],
    },
    filterOptions: {
      billingadmin: {
        role: [
          { id: "superadmin", title: "Super Admin" },
          { id: "accountadmin", title: "Account Admin" },
          { id: "admin", title: "Admin" },
          { id: "user", title: "User" },
        ],
      },
      superadmin: {
        role: [
          { id: "superadmin", title: "Super Admin" },
          { id: "accountadmin", title: "Account Admin" },
          { id: "admin", title: "Admin" },
          { id: "user", title: "User" },
        ],
      },
      accountadmin: {
        role: [
          { id: "admin", title: "Admin", filter: "role" },
          { id: "user", title: "User", filter: "role" },
        ],
      },
      admin: {
        role: [
          { id: "admin", title: "Admin", filter: "role" },
          { id: "user", title: "User", filter: "role" },
        ],
        joinStatus: [
          { id: "joined", title: "Joined", filter: "joinStatus" },
          { id: "pending", title: "Pending", filter: "joinStatus" },
        ],
      },
    },
  },
};

export const events = {
  [state.EVENTS]: {
    priority: {
      high: 50,
      low: 0,
    },
    MAX_EVENT_DURATION: 6,
    MAX_EVENT_DURATION_EXTERNAL_CONTENT: 10,
    MAX_EVENT_DURATION_TOURNAMENT_MODE: 16,
    MAX_EVENT_DURATION_DISABLE_LIVE_PANO: 10,
    TIME_TO_INIT: 1000 * 60 * 15, // 15 minutes
    bulkActions: [bulkActions.EDIT, bulkActions.DELETE],
    MEDIA: {
      IMAGE: {
        BOTTOM_LEFT_IMAGE: "Bottom Left Image",
        BOTTOM_RIGHT_IMAGE: "Bottom Right Image",
        LOGO: "Logo",
      },
    },
    statuses: {
      LIVE: "live",
      UPCOMING: "upcoming",
      INITIALIZING: "initializing",
      VOD: "vod",
      DELETED: "deleted",
      MOVING_TO_VOD: "moving-to-vod",
    },
    tabIndexes: {
      LIVE_INDEX: 0,
      VOD_INDEX: 1,
    },
    views: {
      GRID: "grid",
      LIST: "list",
    },
    pagination: {
      itemsPerPage: [
        { id: 3, name: 3 },
        { id: 6, name: 6 },
        { id: 9, name: 9 },
        { id: 25, name: 25 },
        { id: 50, name: 50 },
        { id: 100, name: 100 },
      ],
    },
    streamTypes: [
      { id: "wowza", name: "RTMP Push" },
      { id: "zixi", name: "Reliable UDP" },
    ],
    gameTypes: {
      GAME: "game",
      OTHER: "other",
      TRAINING: "training",
    },
    streamDownloadStatuses: {
      READY: "Ready",
      PREPARING: "Preparing",
    },
    bulkTableHeaders: {
      EVENT_NAME: "Event name",
      SPORT_TYPE: "Sport type",
      START_DATE: "Start date",
      END_DATE: "End date",
      HOME_TEAM: "Home team",
      AWAY_TEAM: "Away team",
      STRAEM_KEY: "Stream Key",
      STREAM_URL: "Stream URL",
    },
    types: {
      TOURNAMENT: "tournament",
      BETTING: "betting",
      EXTERNAL_CONTENT: "externalContent",
    },
  },
};

export const clubs = {
  [state.CLUBS]: {
    bulkActions: [bulkActions.EDIT, bulkActions.CONVERT_TO_PLAY],
    clubStatuses: [
      { id: CLUB_STATUS_ACTIVE, name: "Active" },
      { id: CLUB_STATUS_HIDDEN, name: "Hidden from search" },
      { id: CLUB_STATUS_DISABLED, name: "Disabled" },
      { id: CLUB_STATUS_EXЕTERNAL_SCHEDULING, name: "External Scheduling" },
    ],
    kickOffTimeList: KICKOFF_TIMES,
    clubStatus: {
      admin: [CLUB_STATUS_ACTIVE, CLUB_STATUS_HIDDEN].join(","),
      accountadmin: [
        CLUB_STATUS_ACTIVE,
        CLUB_STATUS_HIDDEN,
        CLUB_STATUS_DISABLED,
        CLUB_STATUS_EXЕTERNAL_SCHEDULING,
      ].join(","),
      superadmin: [
        CLUB_STATUS_ACTIVE,
        CLUB_STATUS_HIDDEN,
        CLUB_STATUS_DISABLED,
        CLUB_STATUS_EXЕTERNAL_SCHEDULING,
      ].join(","),
      billingadmin: [
        CLUB_STATUS_ACTIVE,
        CLUB_STATUS_HIDDEN,
        CLUB_STATUS_DISABLED,
        CLUB_STATUS_EXЕTERNAL_SCHEDULING,
      ].join(","),
    },
    filterOptions: {
      billingadmin: {
        clubStatus: clubStatusFilterOptions.concat(
          {
            id: CLUB_STATUS_DISABLED,
            title: "Disabled",
          },
          { id: CLUB_STATUS_EXЕTERNAL_SCHEDULING, title: "External Scheduling" },
        ),
        streamType: clubStreamTypeFilterOption,
      },
      superadmin: {
        clubStatus: clubStatusFilterOptions.concat(
          {
            id: CLUB_STATUS_DISABLED,
            title: "Disabled",
          },
          { id: CLUB_STATUS_EXЕTERNAL_SCHEDULING, title: "External Scheduling" },
        ),
        streamType: clubStreamTypeFilterOption,
      },
      admin: {
        clubStatus: clubStatusFilterOptions.concat({
          id: CLUB_STATUS_EXЕTERNAL_SCHEDULING,
          title: "External Scheduling",
        }),
        streamType: clubStreamTypeFilterOption,
      },
      accountadmin: {
        clubStatus: clubStatusFilterOptions.concat(
          {
            id: CLUB_STATUS_DISABLED,
            title: "Disabled",
          },
          { id: CLUB_STATUS_EXЕTERNAL_SCHEDULING, title: "External Scheduling" },
        ),
        streamType: clubStreamTypeFilterOption,
      },
    },
    adminsViewCount: 3,
    streamTypes: {
      ZIXI: "zixi",
    },
  },
};

export const categories = {
  [state.CATEGORIES]: {
    permissions: [
      { name: "Public", id: "public" },
      { name: "Private", id: "private" },
    ],
    filterOptions: {
      permission: [
        { id: "private", title: "Private" },
        { id: "public", title: "Public" },
      ],
    },
    typeOptions: [
      { name: "Main", id: categoryTypes.MAIN },
      { name: "Sub", id: categoryTypes.SUB },
    ],
    types: categoryTypes,
    bulkActions: [bulkActions.EDIT],
  },
};

export const SW_I_FILTER = {
  SW_VERSION: "swVersion",
  VPU_VERSION: "vpuVersion",
  SYSTEM_TYPE: "systemType",
  CAMERA_TYPE: "cameraType",
  INTEL_CPU_NAME: "intelCpuName",
  INTEL_GPU_NAME: "intelGpuName",
  INTEL_DRIVER_VERSION: "intelDriverVersion",
  NVIDIA_GPU_NAME: "nvidiaGpuName",
  NVIDIA_DRIVER_VERSION: "nvidiaDriverVersion",
  INSTALLATION_STATUS: "installationStatus",
  STATUS: "status",
  LIFECYCLE_STATUS: "lifecycleStatus",
  LIFECYCLE_STATUS_REASON: "lifecycleStatusReason",
  OS_TYPE: "osType",
  BACKOFFICE_STATUS: "backofficeStatus",
};

export const OS_TYPES = {
  LINUX: "Linux",
  WINDOW_10: "Win10",
  WINDOW_8: "Win8",
};

export const systems = {
  [state.SYSTEMS]: {
    vpu: {
      MODE: "vpu",
      ALLOWED_STATUSES: ["Live", "Sleep", "Maintenance", "Reset"],
    },
    filterOptions: {
      [SW_I_FILTER.INSTALLATION_STATUS]: {
        NEW: "new",
        READY: "Ready",
        DOWNLOADING: "Downloading",
        DOWNLOAD_FAILED: "Download Failed",
        WAITING_TO_INSTALL: "Waiting To Install",
        INSTALLING: "Installing",
        INSTALLING_RETRY: "Installing Retry",
        POST_INSTALLING: "Post Installing",
        INSTALL_SUCCESS: "Install Success",
        INSTALLED_FAILED: "Install Failed",
        SUCCESS: "success",
        FAILURE: "failure",
        IN_PROGRESS: "inProgress",
        INSTALLING_CHU: "Installing CHU",
        INSTALLING_CHU_FAILED: "CHU Installation Failed",
      },
      [SW_I_FILTER.STATUS]: {
        LIVE: "Live",
        SLEEP: "Sleep",
        RESET: "Reset",
        OFFLINE: "Offline",
        MAINTENANCE: "Maintenance",
      },
      [SW_I_FILTER.OS_TYPE]: OS_TYPES,
      [SW_I_FILTER.BACKOFFICE_STATUS]: {
        ONLINE: "online",
        OFFLINE: "offline",
        NA: "N/A",
      },
    },
    osTypes: OS_TYPES,
    filters: SW_I_FILTER,
    scoreboard: {
      type: {
        notSelected: "NONE_SELECTED",
        notAvailable: "N/A",
        default: "-",
      },
    },
    scoreboardPip: {
      frameResolutions: {
        FRAME_RESOLUTION_720: 720,
        FRAME_RESOLUTION_1080: 1080,
      },
      actions: {
        PREVIEW: "preview",
        PUBLISH: "publish",
        DOWNLOAD: "download",
      },
    },
    bulkActions: [bulkActions.EDIT, bulkActions.RUN_SYSTEM_TEST],
    ALLOWED_SYSTEMS_UPDATE_STATUSES: ["Live", "Sleep", "Reset"],
    UPDATE_PERIOD: 10000,
    systemTypes: {
      PIXELLOT_PRIME: "Pixellot Prime",
      PRIME: "Prime",
    },
    imageTypes: {
      CALIBRATION: "calib",
      PANORAMA: "panorama",
      CAMERA: "camera",
    },
  },
};

export const teams = {
  [state.TEAMS]: {
    maxLoad: 100,
    types: {
      ARCHIVED: "archived",
    },
    rosterTableHeaders: {
      FIRST_NAME: "First name",
      LAST_NAME: "Last name",
      JERSEY_NUMBER: "Jersey number",
      POSTION: "Position",
    },
  },
};

export const analytics = {
  [service.ANALYTICS]: {
    CATEGORY: {
      BUTTON: "Button",
      PAGINATION: "Pagination",
      EXPANDED_SECTION: "Expanded Section",
      STREAM_ACTION: "Stream action",
    },
    ACTION: {
      CLICK: "Click",
      OPEN: "Open",
    },
    LABEL: {
      VPU: "VPU",
      PREVIOUS: "Previous",
      NEXT: "Next",
      SYSTEM_SNAPSHOT: "System Snapshot",
      CALIBRATION_MASK: "Calibration Mask",
    },
    COMMAND: {
      EVENT: "event",
    },
    EVENT: {
      OPEN_VPU: "openVPU",
      PAGINATION_CLICK: "paginationClick",
      EXPANDED_SECTION_OPEN: "expandedSectionOpen",
      STREAM_ACTION_CLICK: "streamActionClick",
      EXTERNAL_CONTENT_CREATE: "externalContentCreate",
      ADDED_BREAKDOWN: "AddedBreakDown",
      ADDED_STREAM_TARGET: "AddedStreamTarget",
      SRT_PULL_STREAM_TARGET: "srt-pull",
      SRT_PUSH_STREAM_TARGET: "srt-push",
      RTMP_STREAM_TARGET: "rtmp",
      ADDED_SET_OF_MAIN_CATEGORY: "AddedSetOfMainCategory",
    },
    CUSTOM_DIMENSIONS: {
      DIMENSION_EVENT_ID: "dimensionEventId",
    },
    INVENTORY: {
      SYSTEM_LIFECYCLE_STATUS_CLICK: "systemLifecycleStatusClick",
      SYSTEM_DEACTIVATE_STATUS_CLICK: "systemDeactivateStatusClick",
    },
  },
};

export const preRolls = {
  [state.PRE_ROLLS]: {
    permissions: [
      {
        name: "live",
        value: "allowForLiveEvents",
        title: "Allow pre-rolls for LIVE",
      },
      {
        name: "vod",
        value: "allowForVODEvents",
        title: "Allow pre-rolls for VOD",
      },
      {
        name: "cut-clip",
        value: "allowForCutClips",
        title: "Allow pre-rolls for cut clips",
      },
      {
        name: "download",
        value: "allowForDownloads",
        title: "Allow pre-rolls for downloads",
      },
    ],
  },
};

export const announce = {
  [state.ANNOUNCE]: {
    types: {
      PREROLL: "preroll",
      MIDROLL: "midroll",
    },
  },
};

export const tools = {
  [state.TOOLS]: {
    tabIndexes: {
      VAST_INDEX: 0,
    },
  },
};

export const accountSettings = {
  [state.ACCOUNT_SETTINGS]: {
    postersTypes: {
      WEB: "web",
      MOBILE: "mobile",
    },
    geoBlockingTypes: {
      WHITE_LIST: "whitelist",
      BLACK_LIST: "blacklist",
    },
    commerceTypes: [
      { id: COMMERCE_TYPE_FREE, name: "Free" },
      { id: COMMERCE_TYPE_MEMBERS, name: "Members" },
      { id: COMMERCE_TYPE_PREMIUM, name: "Premium" },
    ],
  },
};

export const pinnedSystems = {
  [state.PINNED_SYSTEMS]: {
    bulkActions: [
      bulkActions.CLEAR_ALL,
      bulkActions.UPGRADE,
      bulkActions.RUN_SYSTEM_TEST,
      bulkActions.CREATE_TEST_EVENT,
    ],
  },
};

export const upgradesSystems = {
  [state.UPGRADES_SYSTEMS]: {
    bulkActions: [bulkActions.SET_TARGET_VERSION, bulkActions.INSTALL_PACKAGE],
    gaasSystemType: {
      SHOW: "show",
      PRIME: "prime",
    },
  },
};

export const subscription = {
  [state.SUBSCRIPTION]: {
    access: {
      ALL: "all",
      SUB: "sub",
    },
  },
};

export const GENDER_ENUM = {
  MALE: "male",
  FEMALE: "female",
  MIXED: "mixed",
};

export const GENDERS = [
  { id: GENDER_ENUM.MALE, name: "Male" },
  {
    id: GENDER_ENUM.FEMALE,
    name: "Female",
  },
];

export const GENDERS_ALL = [{ id: GENDER_ENUM.MIXED, name: "Co-ed" }, ...GENDERS];
