<template lang="pug">
  .menu
      mixin user-profile
          .user-profile
            img(:src="userProfileIcon" alt="user-profile" title="User profile")
      mixin user-info-drop-down
          popover.dropdown-component.open
              template(v-slot:face="")
                  a(uib-dropdown-toggle :class="{ animate: isAnimationAllowed }")
                      .dropdown-arrow(:class="{ 'open-dropdown': isUserDropdownOpen }")
              template(v-slot:content="")
                  div
                      .dropdown-menu(style="display: block;")
                          .main-info
                              +user-profile
                              .full-name
                                  | {{ user.attributes.fullName }}
                              .email
                                  | {{ user.attributes.email }}
                              router-link.sign-out(to="/logout")
                                  | Sign-Out
                                  span.sign-out-icon
                          .account-section(v-if="isAccountAdmin")
                              span.accounts-header Accounts
                              ul
                                  li(v-for="(tenant, index) in sortedUserTenants" @click="setActiveTenant(null, index)")
                                      label(uib-tooltip="tenant"
                                        show-tooltip-on-text-overflow
                                        tooltip-placement="right"
                                        tooltip-enable="false"
                                        tooltip-append-to-body="true"
                                    ) {{ tenant }}
                                      input.tenant-input(type="radio")
                                      span.check-tenant(v-if="checkTenant(index)")
      aside
          .brand-logo(:class="{ 'stats-logo': isStats }")
              label(for="nav-trigger" :class="{ 'collapse-menu': isMenuOpened }")
                  i.fa.fa-bars(aria-hidden="true")
              .pxl-logo-img(:class="{ 'collapse-pxl-logo': isMenuOpened, 'pxl-logo': !isMenuOpened }")
          input#nav-trigger.nav-trigger(type="checkbox" v-model="isMenuOpened" @change="animationCollapse()")
          .navbar-menu
              ul(:class="{ 'is-opened': isMenuOpened }")
                  li.dark-hover.menu-item(v-for="item in menuItems" :key="item.title" v-if="item.enabled" :class="{ extended: hasMenuItemSubItems(item) && $route.meta.group === item.to.name }" )
                      router-link(v-if="item.to && !hasMenuItemSubItems(item)" :to="item.to" :title="`Go to ${item.title}`" v-slot="{ href, isActive, navigate }" custom)
                        a.menu-link(:class="{ 'selected-item': isActive || $route.meta.group === item.to.name }" :href="href" @click="navigate")
                          img.sidebar-icon(:src="getSideBarIcon(item, isActive || $route.meta.group === item.to.name)" alt="Icon")
                          | &nbsp;
                          span(:class="{ animate: isAnimationAllowed }") {{ item.title }} 

                      router-link.menu-link(v-if="hasMenuItemSubItems(item)" :to="item.to" :class="{ 'selected-item': isRouteGroup(item) }")
                        .menu-sub-item(:class="{ animate: isAnimationAllowed }" )
                          img.sidebar-icon(:src="getSideBarIcon(item, isRouteGroup(item))" alt="Icon")
                          | &nbsp;
                          span(:class="{ animate: isAnimationAllowed }") {{ item.title }}
                          .menu-sub-item-wrapper(v-if="isRouteGroup(item) && hasMenuItemSubItems(item)")
                            li(v-for="s in item.subItems" :key="s.title" v-if="s.enabled")
                              router-link.menu-sub-link(:to="s.to" :class="{ 'selected-sub-link': $route.meta.title === s.title }")
                                span.menu-sub-item-title {{ s.title }} 

                      a.menu-link(v-if="item.clickHandler" @click="item.clickHandler()" :title="item.title")
                          img.sidebar-icon(:src="getSideBarIcon(item)" alt="Sidebar icon")
                          | &nbsp;
                          span(:class="{ animate: isAnimationAllowed }") {{ getIconName(item) }}

                      .logout-item(v-if="item.title === 'Logout'")
                          img.sidebar-icon(:src="getSideBarIcon(item)" alt="Sidebar icon")
                          | &nbsp;
                          span(:class="{ animate: isAnimationAllowed }" uib-dropdown is-open="isUserDropdownOpen")
                              span(uib-dropdown-toggle) {{ getIconName(item) }}
                          +user-info-drop-down

          .menu-profile-info.dropdown-component(:class="{ 'collapsed': isMenuOpened }")
              +user-profile
              .welcome-user(:class="{ animate: isAnimationAllowed }" uib-dropdown is-open="isUserDropdownOpen")
                  div(uib-dropdown-toggle)
                      .name
                          | {{ user && user.attributes.fullName }}
                      .role
                          | {{ userRole }}
              +user-info-drop-down
</template>

<script>
import _ from "lodash";
import { createNamespacedHelpers } from "vuex";
import { Popover } from "@/components/interface";
import { TenantApi } from "@/api";
import { handleActionError, sortArrayAlphabetically } from "@/utils";

const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");

export default {
  name: "PixellotSidebar",
  components: { Popover },
  data() {
    return {
      user: null,
      isMenuOpened: false,
      isUserDropdownOpen: false,
      isAnimationAllowed: false,
      selectedTenant: null,
    };
  },
  computed: {
    ...mapUserGetters([
      "currentUser",
      "isClubAdmin",
      "isAccountAdmin",
      "isSuperAdmin",
      "isBillingAdmin",
      "isBillingOrSuperAdmin",
    ]),
    menuItems() {
      const tenant = this.$route.query.tenant || undefined;
      const clubs = this.$route.query.clubs || undefined;

      return [
        {
          title: "Events",
          to: {
            name: "Events",
            path: "/events",
            query: {
              limit: 9,
              offset: 0,
              sort: "startDateTime",
              tenant,
              clubs,
              status: "upcoming,live,initializing",
            },
          },
          icon: "menu-events",
          enabled: true,
        },
        {
          title: "Inventory",
          to: {
            name: "Inventory",
            path: "/inventory",
            query: { tenant, limit: "10", offset: "0", isActive: true },
          },
          icon: "menu-inventory",
          enabled: !this.isClubAdmin,
          subItems: [
            {
              title: "Fleet Management",
              to: {
                name: "All Systems",
                path: "/inventory/systems",
                query: { tenant, limit: "10", offset: "0", isActive: true },
              },
              enabled: true,
            },
            {
              title: "SW Versions",
              to: {
                name: "SW Versions",
                path: "/sw-versions",
              },
              enabled: this.isBillingOrSuperAdmin,
            },
            {
              title: "Upgrades",
              to: {
                name: "Upgrades",
                path: "/upgrades",
                query: { tenant, limit: "10", offset: "0", isActive: true },
              },
              enabled: this.isBillingOrSuperAdmin,
            },
          ],
        },
        {
          title: "Monitoring",
          to: {
            name: "Monitoring",
            path: "/monitoring",
            query: {
              limit: 25,
              offset: 0,
              sort: "startDateTime",
              tenant,
            },
          },
          icon: "menu-monitoring",
          enabled: true,
        },
        {
          title: "Live events",
          to: {
            name: "LiveEvents",
            path: "/live-events",
            query: {
              limit: 6,
              sort: "startDateTime",
              status: "live",
              offset: 0,
              view: "grid",
              tenant,
              clubs,
            },
          },
          icon: "menu-live-events",
          enabled: true,
        },
        {
          title: "Clubs",
          to: { name: "Clubs", path: "/clubs", query: { limit: 10, offset: 0, tenant } },
          icon: "menu-clubs",
          enabled: true,
        },
        {
          title: "Users",
          to: {
            name: "Users",
            path: "/users",
            query: {
              limit: 10,
              offset: 0,
              sort: "-createdAt",
              joinStatus: this.isClubAdmin ? "joined" : undefined,
            },
          },
          icon: "menu-users",
          enabled: true,
        },
        {
          title: "Teams",
          to: { name: "Teams", path: "/teams", query: { tenant } },
          icon: "menu-teams",
          enabled: !this.isClubAdmin,
        },
        {
          title: "Tools",
          to: { name: "Tools", path: "/tools", query: { tenant } },
          icon: "menu-tools",
          enabled: !this.isClubAdmin,
        },
        {
          title: "Media",
          to: { name: "Media", path: "/media", query: {} },
          icon: "menu-media",
          enabled: this.isClubAdmin,
        },
        {
          title: "Accounts",
          to: { name: "Accounts", path: "/accounts", query: { limit: 100, offset: 0, tenant } },
          icon: "menu-inventory",
          enabled: this.isBillingOrSuperAdmin,
        },
        {
          title: "Marketplace",
          to: { name: "Marketplace", path: "/marketplace", query: { limit: 10, offset: 0 } },
          icon: "menu-extensions",
          enabled: this.isBillingAdmin,
        },
        {
          title: "About",
          to: { name: "About", path: "/about", query: {} },
          icon: "menu-info",
          enabled: true,
        },
        {
          title: "OTT Settings",
          to: {
            name: "General Settings",
            path: "/general-settings/sport-types",
            query: { tenant, limit: 10, offset: 0, tab: 0 },
          },
          icon: "menu-ott-settings",
          enabled: this.isOTTConsumption,
          subItems: [
            {
              title: "General Settings",
              to: {
                name: "General Settings",
                path: "/general-settings/sport-types",
                query: { tenant },
              },
              enabled: true,
            },
            {
              title: "Categories",
              to: {
                name: "Manage Categories",
                path: "/categories/manage",
                query: { tenant, limit: 10, offset: 0, tab: 0 },
              },
              enabled: true,
            },
            {
              title: "Subscriptions",
              to: { name: "Subscriptions", path: "/subscriptions", query: { tenant } },
              enabled: true,
            },
          ],
        },
        {
          title: "Logout",
          enabled: true,
        },
      ];
    },
    userProfileIcon() {
      return _.get(this, "user.attributes.picture") || "assets/images/user-circle.svg";
    },
    userRole() {
      return this.usersRoleFilter(this.user?.attributes.role);
    },
    isStats() {
      const hasStatsClass = document.body?.classList.contains("Stats");

      return globalUrls.title === "Stats" || hasStatsClass;
    },
    isOTTConsumption() {
      return !!this.selectedTenant?.applicationSettings?.contentConsumption?.ott;
    },
    sortedUserTenants() {
      return sortArrayAlphabetically([...this.user?.attributes.tenants]);
    },
  },
  watch: {
    isMenuOpened: {
      handler(value) {
        const elements = [
          document.querySelector(".bottom-bar-wrapper"),
          document.querySelector(".menu"),
        ];

        if (value) {
          elements.forEach((elem) => elem?.classList.add("callapsed-menu"));
        } else {
          elements.forEach((elem) => elem?.classList.remove("callapsed-menu"));
        }

        window.localStorage.setItem("isCollapseMenu", value);
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    usersRoleFilter(role) {
      const usersRoles = {
        billingadmin: "Billing Admin",
        superadmin: "Super Admin",
        accountadmin: "Account Admin",
        admin: "Admin",
      };

      return usersRoles[role];
    },
    async init() {
      this.user = this.currentUser;
      this.isAnimationAllowed = false;
      this.isMenuOpened = JSON.parse(window.localStorage.getItem("isCollapseMenu"));

      if (this.isAccountAdmin) {
        await this.setCurrentTenant(this.$store.state.user.activeTenant);
      }
    },
    animationCollapse() {
      this.isAnimationAllowed = true;
      document.querySelector(".menu").classList.add("transition-menu");
    },
    getSideBarIcon(item, isActive = false) {
      if (item.title === "Logout") {
        return this.userProfileIcon;
      }

      const iconName = isActive ? `${item.icon}-active` : `${item.icon}`;

      return `assets/images/sidebar/${iconName}.svg`;
    },
    getIconName(item) {
      return item.title === "Logout" ? _.get(this, "user.attributes.fullName") : item.title;
    },
    async setActiveTenant(tenant, index = 0) {
      const currentTenant = tenant || this.sortedUserTenants[index];
      const activeTenant = this.$store.state.user.activeTenant;

      if (activeTenant != currentTenant) {
        this.$store.commit("user/SET_ACTIVE_TENANT", currentTenant);

        if (this.isAccountAdmin) {
          await this.setCurrentTenant(currentTenant);
        }
      }
      // Update `tenant` value in query only if such was present
      if (this.$route.query.tenant) {
        this.$updateQuery({ tenant: currentTenant });
      }
    },
    async setCurrentTenant(tenant) {
      const nextTenant = await TenantApi.getTenant(tenant).catch((error) => {
        handleActionError(error, this, "getting tenant");

        return null;
      });

      if (nextTenant) {
        this.selectedTenant = nextTenant;
        this.$store.commit("user/SET_ACTIVE_TENANT_DATA", nextTenant);
      }
    },
    checkTenant($index) {
      const activeTenant = this.$store.state.user.activeTenant;

      return activeTenant === this.sortedUserTenants[$index];
    },
    isRouteGroup(item) {
      return this.$route.meta.group === item.title;
    },
    hasMenuItemSubItems(item) {
      return item.subItems && item.subItems.length;
    },
  },
};
</script>

<style>
.menu .pixellot-popover .pixellot-popover-content .pixellot-popover-content-inner {
  padding: 0;
}
</style>
