import _ from "lodash";
import axios from "./axios";
import { stateRefs, systems } from "@/constants";
import { buildSearchURL, buildResponseArrayGetInterceptor } from "@/utils";

const baseSystemsUrl = "/api/v3/venues";
const basePinnedSystemsUrl = "/api/v3/pinned-systems";

const setDefaultValue = (arrayProperties = [], item = {}) => {
  const mapped = {};

  arrayProperties.forEach((elem) => {
    if (!item[elem]) {
      mapped[elem] = "-";
    }
  });

  return mapped;
};

const systemSearchFilters = [
  { name: "Tenant", value: "tenant" },
  { name: "Name", value: "name" },
  { name: "Venue ID", value: "id" },
  { name: "Version", value: "vpuVersion" },
  { name: "Dongle ID", value: "dongleIdentifier" },
  { name: "Camera FW", value: "cameraFirmware" },
  { name: "Scoreboard Type", value: "scoreboardType" },
  { name: "TV System Mode", value: "tvSystemMode" },
  { name: "OS Type", value: "osType" },
  { name: "Label", value: "systemLabels" },
];

const SEVERITY_COLOR_MAPPER = {
  Ok: "green",
  Warning: "sandybrown",
  Error: "red",
  NA: "red",
};
const SEVERITY_WS_COLOR_MAPPER = {
  0: "green",
  1: "sandybrown",
  2: "red",
  4: "red",
};
const SEVERITY_VALUE_MAPPER = {
  0: "Ok",
  1: "Warning",
  2: "Error",
  4: "NA",
};
const DESCRIPTION_COLOR_MAPPER = {
  Live: "green",
  Maintenance: "sandybrown",
  NotSet: "red",
  Offline: "black",
  Reset: "red",
  Sleep: "black",
};
const SEVERITY_CAMERA_VALUE_MAPPER = {
  Ok: "Good",
  Warning: "Low FPS",
  Error: "Not Connected",
  NA: "Not Connected",
};

const DEFAULT_COLOR = "#2a4c70";
const RED_COLOR = "red";
const GREEN_COLOR = "green";

const VALUE_MAPPER = {
  audioIndication: {
    0: {
      color: GREEN_COLOR,
      value: "Good Audio",
    },
    1: {
      color: RED_COLOR,
      value: "No Audio",
    },
    default: {
      color: DEFAULT_COLOR,
      value: "-",
    },
  },
  darkCourt: {
    0: {
      color: GREEN_COLOR,
      value: "Light",
    },
    1: {
      color: RED_COLOR,
      value: "Dark",
    },
    default: {
      color: DEFAULT_COLOR,
      value: "-",
    },
  },
};

const GENERAL_VENUE_PROPERTIES = ["name", "id", "systemType", "swVersion"];
const VENUE_HWINFO_PROPERTIES = [
  "intelCpuName",
  "intelGpuName",
  "intelDriverVersion",
  "nvidiaGpuName",
  "nvidiaDriverVersion",
];
const VENUE_INSTALLATIONINFO_PROPERTIES = ["completed$date", "status", "reason"];
const VENUE_CAMERA_INFO_PROPERTIES = [
  "partNumber",
  "headType",
  "physicalMode",
  "pairedMode",
  "chosenFps",
];

const FILTERS_VALUES = [
  "status",
  "intelCpuName",
  "intelGpuName",
  "intelDriverVersion",
  "nvidiaGpuName",
  "nvidiaDriverVersion",
  "lifecycleStatus",
  "systemType",
  "cameraType",
  "installationStatus",
  "vpuVersion",
  "lifecycleStatusReason",
  "remoteControllerStatus",
];
const PINNED_SYSTEMS_FILTERS_VALUES = [
  "status",
  "vpuVersion",
  "systemType",
  "lifecycleStatus",
  "lifecycleStatusReason",
  "installationStatus",
  "osType",
  "nvidiaDriverVersion",
  "sportType",
  "scoreboardType",
];
const UPGRADES_SYSTEMS_FILTERS_VALUES = [
  "status",
  "vpuVersion",
  "systemType",
  "lifecycleStatus",
  "lifecycleStatusReason",
  "installationStatus",
  "osType",
  "nvidiaDriverVersion",
  "sportType",
  "scoreboardType",
  "remoteControllerStatus",
];
const WS_METRICS_PROPERTIES = [
  "health",
  "cpu",
  "gpu",
  "status",
  "camera",
  "connection",
  "hdBandwidth",
  "panoBandwidth",
  "scoreboardConnection",
  "scoreboardData",
  "audioIndication",
  "darkCourt",
];
const SYSTEM_GENERAL_PROPERTIES = ["name", "tenant"];

const LIFECYCLE_SYSTEM_STATUS_IN_TRANSIT = "InTransit";

const LIFECYCLE_SYSTEM_STATUSES = [
  { id: LIFECYCLE_SYSTEM_STATUS_IN_TRANSIT, name: "In Transit" },
  { id: "Installed", name: "Installed" },
  { id: "Production", name: "Production" },
  { id: "Maintenance", name: "Maintenance" },
  { id: "Manufacturing", name: "Manufacturing" },
  { id: "RMA", name: "RMA" },
];

const venueRegions = {
  "us-east-2": "US East (Ohio)",
  "us-east-1": "US East (N. Virginia)",
  "us-west-1": "US West (N. California)",
  "us-west-2": "US West (Oregon)",
  "af-south-1": "Africa (Cape Town)",
  "ap-east-1": "Asia Pacific (Hong Kong)",
  "ap-south-1": "Asia Pacific (Mumbai)",
  "ap-northeast-3": "Asia Pacific (Osaka)",
  "ap-northeast-2": "Asia Pacific (Seoul)",
  "ap-southeast-1": "Asia Pacific (Singapore)",
  "ap-southeast-2": "Asia Pacific (Sydney)",
  "ap-northeast-1": "Asia Pacific (Tokyo)",
  "ca-central-1": "Canada (Central)",
  "eu-central-1": "Europe (Frankfurt)",
  "eu-west-1": "Europe (Ireland)",
  "eu-west-2": "Europe (London)",
  "eu-south-1": "Europe (Milan)",
  "eu-west-3": "Europe (Paris)",
  "eu-north-1": "Europe (Stockholm)",
  "me-south-1": "Middle East (Bahrain)",
  "sa-east-1": "South America (São Paulo)",
};

const systemLabels = [
  "SWBetaDrop1",
  "SWBetaDrop2",
  "SWBetaDrop3",
  "NeedValidation",
  "ToBeUpgraded",
  "ProactiveCamera",
  "BetaSite",
  "HighProfile",
  "Product",
  "Label1",
];

const SELECTION_MODE_OPTION = [
  { id: "add", name: "Add to existing" },
  { id: "replace", name: "Replace all with" },
];

export default {
  getSystems(params = {}) {
    return axios
      .get(`${baseSystemsUrl}${buildSearchURL(params)}`)
      .then((response) => buildResponseArrayGetInterceptor(response, params));
  },

  partialUpdate(venueId, data) {
    return axios.patch(`${baseSystemsUrl}/${venueId}`, data);
  },

  getSystemsStatus(venuesIds) {
    return axios
      .get(`${baseSystemsUrl}/status/venues/${buildSearchURL({ venues: venuesIds })}`)
      .then((response) => buildResponseArrayGetInterceptor(response));
  },

  createSystem(data) {
    return axios.post(`${baseSystemsUrl}/`, data);
  },

  getSystemStatus(venueId, params) {
    return axios
      .get(`${baseSystemsUrl}/${venueId}/status${buildSearchURL(params)}`)
      .then((data) => ({ data: data.data }));
  },

  getMetricsForSystems(params) {
    return axios
      .get(`${baseSystemsUrl}/metrics/venues${buildSearchURL(params)}`)
      .then((response) => buildResponseArrayGetInterceptor(response, params))
      .then((response) => {
        response.items = response.items.map((item) => {
          Object.keys(item.Metrics).forEach((key) => {
            if (item.Metrics[key] !== null) {
              item[key] = item.Metrics[key];
            }
          });
          return item;
        });

        return response;
      });
  },

  runSystemCommand(systemIds, command) {
    return axios.put(baseSystemsUrl, { systems: systemIds, command });
  },

  getSystemById(venueId) {
    return axios.get(`${baseSystemsUrl}/${venueId}`).then((item) => item.data);
  },

  activateSystemAction(action, venueId) {
    const payload = { commandName: action.name, parameters: action.parameters };

    return axios
      .post(`${baseSystemsUrl}/${venueId}/commands`, payload)
      .then((response) => response.data);
  },

  getSystemAction(venueId, commandId) {
    return axios
      .get(`${baseSystemsUrl}/${venueId}/scoreboard/commands/${commandId}`)
      .then((response) => Promise.resolve(response.data));
  },

  getCameraFrame(venueId) {
    return axios
      .get(`${baseSystemsUrl}/${venueId}/scoreboard/frame`)
      .then((data) => Promise.resolve(data.data));
  },

  getCourtAreas(venueId) {
    return axios
      .get(`${baseSystemsUrl}/${venueId}/scoreboard/court-areas`)
      .then((data) => Promise.resolve(data.data));
  },

  getScoreboardTemplates(venueId) {
    return axios
      .get(`${baseSystemsUrl}/${venueId}/scoreboard/templates`)
      .then((data) => Promise.resolve({ items: data.data }));
  },

  getScoreboardObjects(venueId) {
    return axios
      .get(`${baseSystemsUrl}/${venueId}/scoreboard/objects`)
      .then((data) => Promise.resolve({ items: data.data }));
  },

  getTargetVersions() {
    return axios.get(`${baseSystemsUrl}/gaas/versions`).then(({ data }) => data);
  },

  generateScoreboard(venueId, pip, action, files = []) {
    const formData = new FormData();
    const httpConfig = {};

    if (action === "download") {
      httpConfig.responseType = "blob";
    }

    files.forEach((file, index) => formData.append(`files[${index}]`, file));
    formData.append("data", JSON.stringify(pip));

    return axios
      .post(`${baseSystemsUrl}/${venueId}/${action}ScoreboardFile`, formData, httpConfig)
      .then((response) => {
        return response.data ? response.data : response;
      });
  },

  mapMainBeSystemsData(item) {
    let mapped = {
      hwInfo: {},
      installationInfo: {},
    };

    mapped.tenant = typeof item.tenant === "string" ? item.tenant : "-";
    mapped.cameraType = this.getCameraType(item.cameraHeadInfo);

    mapped = Object.assign(mapped, setDefaultValue(GENERAL_VENUE_PROPERTIES, item));

    const venueInfoObjects = {
      hwInfo: VENUE_HWINFO_PROPERTIES,
      installationInfo: VENUE_INSTALLATIONINFO_PROPERTIES,
    };

    Object.entries(venueInfoObjects).forEach(([key, val]) => {
      val.forEach((elem) => {
        if (!item[key][elem]) {
          mapped[key][elem] = "-";

          return;
        }
        mapped[key][elem] = item[key][elem];
      });
    });

    return mapped;
  },

  getCameraType(item) {
    return _.get(item, "headCameras[0].type") || "-";
  },

  getCameraFilmware(item) {
    return _.get(item, "headCameras[0].firmware") || "-";
  },

  getTVSystemMode(item) {
    return _.get(item, "headCameras[0].tvSystemMode") || "-";
  },

  getVPUStatusClass(item) {
    const { LIVE, OFFLINE, SLEEP, MAINTENANCE, RESET } =
      systems[stateRefs.system].filterOptions.status;

    switch (item.originalItem.status) {
      case LIVE:
        return { live: true };
      case OFFLINE:
        return { offline: true };
      case SLEEP:
        return { sleep: true };
      case MAINTENANCE:
        return { maintenance: true };
      case RESET:
        return { reset: true };
      default:
        return { default: true };
    }
  },

  mapSystemCameraInfo(item) {
    const mapped = {};

    const headCameraInfo = {
      type: this.getCameraType(item),
      firmware: this.getCameraFilmware(item),
      tvSystemMode: this.getTVSystemMode(item),
    };
    const cameraInfo = setDefaultValue(VENUE_CAMERA_INFO_PROPERTIES, item);

    return Object.assign(mapped, headCameraInfo, cameraInfo);
  },

  mapSystemGraphicsInfo(item) {
    const types = systems[stateRefs.system].scoreboard.type;
    const scoreboardType = item.scoreboardType;

    if (!scoreboardType) {
      return {
        scoreboardType: types.default,
      };
    }

    if (scoreboardType === types.notSelected) {
      return {
        scoreboardType: types.notAvailable,
      };
    }

    return {
      scoreboardType,
    };
  },

  mapHeadCameras(item) {
    return setDefaultValue(["type", "firmware"], item);
  },

  mapSystemsData(item) {
    let version = _.get(item, "vpuVersion");

    if (!version) {
      version = _.get(item, "swVersion");
    }

    if (!version) {
      version = _.get(item, "systems.vpu.swVersion");
    }

    item.swVersion = version || "-";
    item.systemType = item.systemInfo?.systemType || "-";
    item.installationStatus = item.installationInfo?.status || "-";

    [
      "name",
      "dongleIdentifier",
      "tenant",
      "country",
      "region",
      "status",
      "lifecycleStatusReason",
      "lifecycleStatus",
      "osType",
      "scoreboardType",
      "remoteControllerStatus",
      "sportType",
      "targetVersion",
    ].forEach((elem) => {
      if (!item[elem]) {
        item[elem] = "-";
      }
    });

    return item;
  },

  mapWsData(item, metric = {}) {
    let mapped = {};

    Object.keys(item).forEach((elem) => {
      if (WS_METRICS_PROPERTIES.includes(elem)) {
        switch (elem) {
          case "connection":
            mapped[elem] = this.buildConnMetricObject(item, elem, false);
            break;
          case "camera":
            mapped[elem] = this.buildWSCameraMetricObject(item, elem);
            break;
          case "status":
            mapped[elem] = this.buildMetricSpecificObject(item, elem);
            break;
          case "cpu":
          case "gpu":
          case "hdBandwidth":
          case "panoBandwidth":
            mapped[elem] = this.buildWSExactMetricObject(item, elem);
            break;
          case "scoreboardConnection":
          case "scoreboardData":
            mapped[elem] = this.buildWSMetricObject(item, elem);
            break;
          case "audioIndication":
          case "darkCourt":
            mapped[elem] = this.buildObjectByValue(item, elem);
            break;
          default:
            mapped[elem] = this.buildWSMetricObject(item, elem);
            break;
        }
      }
      if (SYSTEM_GENERAL_PROPERTIES.includes(elem)) {
        mapped[elem] = item[elem];
      }
    });

    if (mapped.status) {
      mapped = Object.assign(mapped, this.mapSpecificSystemData(mapped, metric));
    }

    return mapped;
  },

  mapSpecificSystemData(data, metric = {}) {
    const metrics = {};

    let notEditableAttributes;

    let hardwareAttributes = ["hdBandwidth", "panoBandwidth", "cpu", "gpu"];

    if (["NotSet", "Offline", "-"].includes(data.status.value)) {
      notEditableAttributes = [
        "status",
        "scoreboardConnection",
        "scoreboardData",
        "audioIndication",
        "darkCourt",
      ];
      hardwareAttributes = [];
    }

    if (["Sleep", "Maintenance"].includes(data.status.value)) {
      notEditableAttributes = [
        "camera",
        "status",
        "connection",
        "scoreboardConnection",
        "scoreboardData",
        "audioIndication",
        "darkCourt",
      ];
    }

    if (!notEditableAttributes) {
      return;
    }

    WS_METRICS_PROPERTIES.forEach((prop) => {
      if (notEditableAttributes.indexOf(prop) === -1) {
        if (hardwareAttributes.includes(prop)) {
          const exactValue = (data[prop] && data[prop].exact) || this.mapExactValue(metric, prop);

          metrics[prop] = this.buildMetricExactValue({ exact: exactValue }, prop);

          return;
        }
        metrics[prop] = this.buildMetricObject({}, prop);
      }
    });

    return metrics;
  },

  mapSystemMetrics(item) {
    let metrics = {
      connection: this.buildConnMetricObject(item, "connection"),
      health: this.buildMetricObject(item, "health"),
      camera: this.buildCameraMetricObject(item, "camera"),
      cpu: this.buildMetricExactObject(item, "cpu"),
      gpu: this.buildMetricExactObject(item, "gpu"),
      hdBandwidth: this.buildMetricExactObject(item, "hdBandwidth"),
      panoBandwidth: this.buildMetricExactObject(item, "panoBandwidth"),
      status: this.buildMetricSpecificObject(item, "status"),
      scoreboardConnection: this.buildMetricExactObject(item, "scoreboardConnection"),
      scoreboardData: this.buildMetricExactObject(item, "scoreboardData"),
      audioIndication: this.buildObjectByValue(item, "audioIndication"),
      darkCourt: this.buildObjectByValue(item, "darkCourt"),
    };

    metrics = _.merge(metrics, this.mapSpecificSystemData(metrics));

    return metrics;
  },

  buildConnMetricObject(item, prop, isInitial = true) {
    const connection = isInitial
      ? this.buildMetricObject(item, prop)
      : this.buildWSMetricObject(item, prop);

    switch (connection.value) {
      case "Ok":
        connection.value = "Connected";
        break;
      case "Error":
        connection.value = "Not Connected";
        break;
    }

    return connection;
  },

  buildCameraMetricObject(item, prop) {
    return {
      value:
        item[prop] && item[prop].severity ? SEVERITY_CAMERA_VALUE_MAPPER[item[prop].severity] : "-",
      color: DEFAULT_COLOR,
    };
  },

  buildWSCameraMetricObject(item, prop) {
    const value = SEVERITY_VALUE_MAPPER[item[prop].severity];

    return {
      value: SEVERITY_CAMERA_VALUE_MAPPER[value],
      color: DEFAULT_COLOR,
    };
  },

  buildWSMetricObject(item, prop) {
    return {
      value: SEVERITY_VALUE_MAPPER[item[prop].severity],
      color: SEVERITY_WS_COLOR_MAPPER[item[prop].severity],
    };
  },

  buildWSExactMetricObject(item, prop) {
    return {
      exact: this.mapExactValue(item, prop),
      value: SEVERITY_VALUE_MAPPER[item[prop].severity],
      color: SEVERITY_WS_COLOR_MAPPER[item[prop].severity],
    };
  },

  buildMetricSpecificObject(item, prop) {
    return {
      value: item[prop] && item[prop].description ? item[prop].description : "-",
      color:
        item[prop] && item[prop].description
          ? DESCRIPTION_COLOR_MAPPER[item[prop].description]
          : DEFAULT_COLOR,
    };
  },

  buildMetricObject(item, prop) {
    return {
      value: item[prop] ? item[prop].severity : "-",
      color: item[prop] ? SEVERITY_COLOR_MAPPER[item[prop].severity] : DEFAULT_COLOR,
    };
  },

  buildMetricExactObject(item, prop) {
    return _.merge({}, this.buildMetricObject(item, prop), this.buildMetricExactValue(item, prop));
  },

  buildMetricExactValue(item, prop) {
    return {
      exact: item.exact || this.mapExactValue(item, prop),
    };
  },

  mapExactValue(item, prop) {
    const value = item[prop] && item[prop].value;

    if (!value) {
      return "-";
    }

    if (item[prop].exact) {
      return item[prop].exact;
    }

    if (value === -1) {
      return -1;
    }

    switch (prop) {
      case "cpu":
      case "gpu":
        return `${(Math.round(value * 100) / 100).toFixed(2)}%`;
      case "hdBandwidth":
      case "panoBandwidth":
        return `${(value / 1000).toFixed(2)}Mb`;
      default:
    }
  },

  buildObjectByValue(item, prop) {
    const value = _.get(item, `${prop}.value`);
    const mapper = VALUE_MAPPER[prop];

    const mappedValue = mapper[value] || mapper.default;

    return {
      value: mappedValue.value,
      color: mappedValue.color,
    };
  },

  getFilterOptions(params) {
    const configValue = systems[stateRefs.system].filterOptions[params.field];

    if (configValue) {
      return Promise.resolve({
        data: Object.values(configValue),
      });
    }

    return axios
      .get(`${baseSystemsUrl}/distinct${buildSearchURL(params)}`)
      .then((response) => response.data);
  },

  getSystemsExportData(params) {
    return axios
      .get(`${baseSystemsUrl}/export-data${buildSearchURL(params)}`)
      .then((response) => response);
  },

  getPinnedSystemsExportData(params) {
    return axios
      .get(`${basePinnedSystemsUrl}/export-data${buildSearchURL(params)}`)
      .then((response) => response);
  },

  getLastImage(systemId, type) {
    return axios
      .get(`${baseSystemsUrl}/${systemId}/${type}/last-image`, { responseType: "blob" })
      .then((response) => response);
  },

  pinSystem(systemId) {
    return axios.post(basePinnedSystemsUrl, { systemId });
  },

  getPinnedSystems(params) {
    return axios
      .get(`${basePinnedSystemsUrl}${buildSearchURL(params)}`)
      .then((response) => buildResponseArrayGetInterceptor({ data: response }, params))
      .then((response) => {
        response.items = response.items.map((item) => {
          Object.keys(item.Metrics).forEach((key) => {
            if (item.Metrics[key] !== null) {
              item[key] = item.Metrics[key];
            }
          });
          return item;
        });

        return response;
      });
  },

  unPinSystem(systemId) {
    return axios.delete(basePinnedSystemsUrl, { data: { systemIds: [systemId] } });
  },

  deleteAllPinnedSystems() {
    return axios.delete(`${basePinnedSystemsUrl}/all`);
  },

  rebootSystem(systemId) {
    return axios.post(`${baseSystemsUrl}/${systemId}/reboot`).then((response) => response);
  },

  refreshBackofficeStatus(systemId) {
    return axios.post(`${baseSystemsUrl}/${systemId}/refresh`);
  },

  setSystemsTargetVersion(data) {
    return axios.post(`${baseSystemsUrl}/target-version`, data).then((response) => response.data);
  },

  installSystemPackage(data) {
    return axios.post(`${baseSystemsUrl}/package`, data).then((response) => response.data);
  },

  bulkUpdateSystems(formData) {
    return axios.patch(`${baseSystemsUrl}/bulk`, formData).then((response) => {
      return {
        updatedSystems: response.data.updated,
        failedSystems: response.data.failed,
      };
    });
  },

  get searchFilters() {
    return systemSearchFilters;
  },
  get filtersValues() {
    return FILTERS_VALUES;
  },
  get pinnedSystemsFiltersValues() {
    return PINNED_SYSTEMS_FILTERS_VALUES;
  },
  get upgradesSystemsFiltersValues() {
    return UPGRADES_SYSTEMS_FILTERS_VALUES;
  },
  get lifecycleSystemStatuses() {
    return LIFECYCLE_SYSTEM_STATUSES;
  },
  get lifecycleSystemStatusInTransit() {
    return LIFECYCLE_SYSTEM_STATUS_IN_TRANSIT;
  },
  get venueRegions() {
    return venueRegions;
  },
  get systemLabels() {
    return systemLabels;
  },
  get modeOptions() {
    return SELECTION_MODE_OPTION;
  },
};
