var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onOutsideClick,
          expression: "onOutsideClick",
        },
      ],
      staticClass:
        "multi-select-checkbox-component dropdown-component header-button-block-wrapper dropdown",
      class: { active: _vm.count, open: _vm.isDropdownOpen },
    },
    [
      _c(
        "button",
        {
          staticClass: "btn dropdown-btn dropdown-toggle",
          attrs: { "aria-expanded": _vm.isDropdownOpen },
          on: { click: _vm.toggleDropdown },
        },
        [
          _c("span", {
            staticClass: "dropdown-arrow",
            class: { "open-dropdown": _vm.isDropdownOpen },
          }),
          _vm.count
            ? _c("span", { staticClass: "count-items" }, [
                _vm._v(_vm._s(_vm.count)),
              ])
            : _vm._e(),
          _vm.showTitle
            ? _c("span", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.filterName)),
              ])
            : _vm._e(),
          _vm.count
            ? _c(
                "a",
                { staticClass: "clear-items", on: { click: _vm.clear } },
                [_vm._v(" ")]
              )
            : _vm._e(),
        ]
      ),
      _c("div", { staticClass: "dropdown-menu" }, [
        _c("div", { staticClass: "dropdown-menu-container" }, [
          _vm.isSearch
            ? _c("div", { staticClass: "search-area" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.search,
                      expression: "search",
                    },
                  ],
                  staticClass: "search-field",
                  attrs: { placeholder: _vm.searchPlaceholder },
                  domProps: { value: _vm.search },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.search = $event.target.value
                      },
                      _vm.searchValue,
                    ],
                  },
                }),
              ])
            : _vm._e(),
          _vm.isSearch ? _c("div", { staticClass: "divider" }) : _vm._e(),
          _c(
            "ul",
            {
              directives: [
                {
                  name: "infinite-scroll",
                  rawName: "v-infinite-scroll",
                  value: _vm.loadMoreData,
                  expression: "loadMoreData",
                },
              ],
              ref: "scrollList",
            },
            [
              _c(
                "li",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.allowAllOptions,
                      expression: "allowAllOptions",
                    },
                  ],
                },
                [
                  _c(
                    "checkbox",
                    {
                      model: {
                        value: _vm.selectedAll,
                        callback: function ($$v) {
                          _vm.selectedAll = $$v
                        },
                        expression: "selectedAll",
                      },
                    },
                    [_vm._v("Select All")]
                  ),
                ],
                1
              ),
              _vm._l(_vm.filterOptions, function (option) {
                return _c(
                  "li",
                  { key: option.id },
                  [
                    _c(
                      "checkbox",
                      {
                        attrs: { value: _vm.isChecked(option) },
                        on: {
                          input: function ($event) {
                            return _vm.toggleOption(option)
                          },
                        },
                      },
                      [_vm._v(_vm._s(option.displayName || option.name))]
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _c("div", { staticClass: "divider" }),
          _c(
            "a",
            {
              staticClass: "apply-area",
              class: {
                active: _vm.allowApply === true && _vm.filterOptions.length,
              },
              on: { click: _vm.applyFilters },
            },
            [_vm._v("Apply")]
          ),
          _vm.isLoading
            ? _c("div", { staticClass: "dropdown-overlay" }, [_vm._m(0)])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loader" }, [
      _c("div", { staticClass: "loader_circle", attrs: { id: "lrotate_01" } }),
      _c("div", { staticClass: "loader_circle", attrs: { id: "lrotate_02" } }),
      _c("div", { staticClass: "loader_circle", attrs: { id: "lrotate_03" } }),
      _c("div", { staticClass: "loader_circle", attrs: { id: "lrotate_04" } }),
      _c("div", { staticClass: "loader_circle", attrs: { id: "lrotate_05" } }),
      _c("div", { staticClass: "loader_circle", attrs: { id: "lrotate_06" } }),
      _c("div", { staticClass: "loader_circle", attrs: { id: "lrotate_07" } }),
      _c("div", { staticClass: "loader_circle", attrs: { id: "lrotate_08" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }