export default class SelectedItem {
  constructor() {
    this.storage = {};
    this.itemsCount = 0;
  }

  has(key) {
    return Boolean(this.storage[key]);
  }

  add(key, value) {
    if (!this.has(key)) {
      this.storage[key] = value;
      this.itemsCount++;
    }
  }

  remove(key) {
    if (this.has(key)) {
      delete this.storage[key];
      this.itemsCount--;
    }
  }

  reset() {
    this.storage = {};
    this.itemsCount = 0;
  }

  setCount(count) {
    this.itemsCount = count;
  }

  get count() {
    return this.itemsCount;
  }

  get selectedItems() {
    return Object.values(this.storage);
  }

  get selectedItemsKeys() {
    return Object.keys(this.storage);
  }
}
