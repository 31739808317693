const AddClubToSystemPopup = () => import("@inventory/components/modals/AddClubToSystemPopup.vue");
const ImagePopup = () => import("@inventory/components/modals/ImagePopup.vue");
const InstallerFormModal = () => import("@inventory/components/modals/InstallerFormModal.vue");
const PackageFormModal = () => import("@inventory/components/modals/PackageFormModal.vue");
const SystemFormModal = () => import("@inventory/components/modals/SystemFormModal.vue");
const UpgradeSystemFormModal = () =>
  import("@inventory/components/modals/UpgradeSystemFormModal.vue");
const DoubleConfirmationPopup = () =>
  import("@inventory/components/modals/doubleConfirmationPopup.vue");
const TestEventFormModal = () => import("@inventory/components/modals/TestEventFormModal.vue");
const AddNewTabModal = () => import("@inventory/components/modals/AddNewTabModal.vue");
const SetTargetVersionModal = () =>
  import("@inventory/components/modals/SetTargetVersionModal.vue");
const InstallPackageFormModal = () =>
  import("@inventory/components/modals/InstallPackageFormModal.vue");
const SystemBulkFormModal = () => import("@inventory/components/modals/SystemBulkFormModal.vue");

export default {
  AddClubToSystemPopup,
  ImagePopup,
  InstallerFormModal,
  PackageFormModal,
  SystemFormModal,
  UpgradeSystemFormModal,
  DoubleConfirmationPopup,
  TestEventFormModal,
  AddNewTabModal,
  SetTargetVersionModal,
  InstallPackageFormModal,
  SystemBulkFormModal,
};
