import _ from "lodash";
import { AuthApi } from "@/api";
import firebase from "@/plugins/firebase";

const state = () => {
  return {
    data: null,
    activeTenant: null,
    activeTenantData: null,
    temporarySystemsTabs: [],
  };
};

const getters = {
  currentUser(state) {
    return state.data;
  },
  role(state) {
    return state.data?.attributes?.role || null;
  },
  tenants(state) {
    return state.data?.attributes?.tenants || [];
  },
  userAdminClubs(state) {
    return _.get(state.data, "attributes.admin.clubs", []);
  },
  isSuperAdmin(state, getters) {
    return getters.role === "superadmin";
  },
  isClubAdmin(state, getters) {
    return getters.role === "admin";
  },
  isAccountAdmin(state, getters) {
    return getters.role === "accountadmin";
  },
  isBillingAdmin(state, getters) {
    return getters.role === "billingadmin";
  },
  isFirebaseProvider(state) {
    return state.data?.attributes.provider === "firebase";
  },
  isBillingOrSuperAdmin(state, getters) {
    return getters.role === "billingadmin" || getters.role === "superadmin";
  },
  activeTenant(state) {
    return state.activeTenant;
  },
};

const actions = {
  async fetchUser(store) {
    try {
      const user = await AuthApi.getUsersMe();

      if (store.getters.isFirebaseProvider) {
        const firebaseUser = await firebase.getUser(store.state.data.attributes.email);

        if (firebaseUser) {
          user.data.attributes.fullName = firebaseUser.data.attributes.fullName;
        }
      }

      const authToken = await AuthApi.getAuthToken();
      const role = user.data.attributes.role;

      if (authToken) {
        AuthApi.setAuthorizationHeader(authToken);
      }

      if (role === "accountadmin") {
        const defaultTenant = user.data.attributes.tenants[0];
        const currentTenant = store.state.activeTenant || defaultTenant;

        store.commit("SET_ACTIVE_TENANT", currentTenant);
      }

      store.commit("SET_USER", user.data);
    } catch (err) {
      store.dispatch("auth/logout", null, { root: true });
    }
  },
};

const mutations = {
  SET_USER(state, payload) {
    state.data = payload;
  },
  SET_ACTIVE_TENANT(state, payload) {
    state.activeTenant = payload;
  },
  SET_ACTIVE_TENANT_DATA(state, payload) {
    state.activeTenantData = payload;
  },
  SET_TEMPORARY_SYSTEM_TAB(state, payload) {
    state.temporarySystemsTabs = payload;
  },
  ADD_TEMPORARY_SYSTEM_TAB(state, payload) {
    state.temporarySystemsTabs.push(payload);
  },
  REMOVE_TEMPORARY_SYSTEM_TAB(state, index) {
    state.temporarySystemsTabs.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
