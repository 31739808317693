<template lang="pug">
    .multi-select-checkbox-component.dropdown-component.header-button-block-wrapper.dropdown(
        :class="{ active: count, open: isDropdownOpen }"
        v-click-outside="onOutsideClick"
    )
        button.btn.dropdown-btn.dropdown-toggle(@click="toggleDropdown" :aria-expanded="isDropdownOpen")
            span.dropdown-arrow(:class="{ 'open-dropdown': isDropdownOpen }")
            span.count-items(v-if="count") {{ count }}
            span.title(v-if="showTitle") {{ filterName }}
            a.clear-items(@click="clear" v-if="count") &nbsp;
        .dropdown-menu
            .dropdown-menu-container
                .search-area(v-if="isSearch")
                    input.search-field(
                        :placeholder="searchPlaceholder"
                        @input="searchValue"
                        v-model="search"
                    )
                .divider(v-if="isSearch")
                ul(v-infinite-scroll="loadMoreData" ref="scrollList")
                    li(v-show="allowAllOptions")
                        checkbox(v-model="selectedAll") Select All
                    li(v-for="option in filterOptions" :key="option.id")
                        checkbox(:value="isChecked(option)" @input="toggleOption(option)") {{ option.displayName || option.name }}
                .divider
                a.apply-area(@click="applyFilters" :class="{ active: allowApply === true && filterOptions.length }")
                    | Apply
                .dropdown-overlay(v-if="isLoading")
                    .loader
                        #lrotate_01.loader_circle
                        #lrotate_02.loader_circle
                        #lrotate_03.loader_circle
                        #lrotate_04.loader_circle
                        #lrotate_05.loader_circle
                        #lrotate_06.loader_circle
                        #lrotate_07.loader_circle
                        #lrotate_08.loader_circle
</template>

<script>
import _ from "lodash";
import SelectedItem from "./SelectedItem";
import { InfiniteScroll, ClickOutside } from "@/utils/directives";
import { Checkbox } from "@/components/shared";

export default {
  name: "DropdownCheckbox",
  components: { Checkbox },
  directives: { InfiniteScroll, ClickOutside },
  props: {
    filterName: {
      type: String,
      required: true,
    },
    filterOptions: {
      type: Array,
      required: true,
    },
    selectedItem: {
      type: Array,
      required: true,
    },
    isSearch: {
      type: Boolean,
      default: true,
    },
    allOptions: Boolean,
    searchPlaceholder: {
      type: String,
      default: "",
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    isInverseFilter: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const checkedItems = new SelectedItem();

    return {
      allowApply: false,
      isDropdownOpen: false,
      search: "",
      checkedItems,
      selectedAll: false,
    };
  },
  computed: {
    allowAllOptions() {
      return this.allOptions !== false;
    },
    count() {
      return this.checkedItems.count;
    },
  },
  watch: {
    selectedItem: "setItems",
    selectedAll: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$emit("load-all", this.search);
          this.checkedAll();
        } else if (
          !value &&
          this.checkedItems.itemsCount === this.filterOptions.length &&
          this.isDropdownOpen
        ) {
          this.checkedItems.reset();
        }
        this.allowApply = true;
      },
    },
    filterOptions: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal && newVal.length !== oldVal.length && this.selectedAll) {
          this.checkedAll();
          this.allowApply = true;
        }
      },
    },
  },
  mounted() {
    this.setItems();
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      this.selectedAll =
        this.filterOptions.length > 0 && this.checkedItems.itemsCount === this.filterOptions.length;
      this.setItems();
    },
    onOutsideClick() {
      this.isDropdownOpen = false;
      this.setItems();
    },
    clear() {
      this.allowApply = true;
      this.selectedAll = false;
      this.checkedItems.reset();
      this.applyFilters();
    },
    toggleOption({ id }) {
      this.allowApply = true;

      if (this.selectedAll) {
        this.selectedAll = false;
      }

      this.checkedItems.has(id) ? this.checkedItems.remove(id) : this.checkedItems.add(id, true);
    },
    setItems() {
      if (!this.isInverseFilter) {
        this.checkedItems.reset();
      }
      (this.selectedItem || []).forEach((key) => this.checkedItems.add(key, true));
    },
    loadMoreData() {
      if (this.selectedAll) {
        return;
      }

      this.$emit("load-more", this.search);
    },
    searchValue: _.debounce(function () {
      this.$emit("search", { q: this.search });

      this.$refs.scrollList.scrollTop = 0;
    }, 350),
    isChecked({ id }) {
      return this.checkedItems.has(id);
    },
    checkedAll() {
      this.filterOptions.forEach((option) => this.checkedItems.add(option.id, true));
      this.checkedItems.setCount(this.filterOptions.length);
    },
    applyFilters() {
      if (!this.allowApply) {
        return;
      }

      if (this.search) {
        // Uncheck items that are not in the filterOptions
        this.checkedItems.selectedItemsKeys.forEach((key) => {
          if (!this.filterOptions.find((option) => option.id === key)) {
            this.checkedItems.remove(key);
          }
        });
      }

      this.isDropdownOpen = false;
      this.allowApply = false;
      this.search = "";
      this.selectedAll = false;

      this.$emit("select-filter", {
        filterName: this.filterName,
        values: this.checkedItems.selectedItemsKeys,
      });
    },
  },
};
</script>

<style lang="stylus">
.multi-select-checkbox-component
    &.header-button-block-wrapper
        padding 0

    .dropdown-menu
        left -30px

        .search-area
            margin 10px

            .search-field
                border none
                outline none
                font-size 13px
                line-height 2
                width 100%

                label
                    margin 0
                    cursor pointer
                    font-weight normal

                    .checkbox-component
                        margin-right 11px

                        &:before
                            margin-bottom -2px

        .apply-area
            line-height 2
            color label-color
            padding-left 16px
            cursor not-allowed

            &.active
                color text-color
                cursor pointer

        .divider
            background-color #ddd

    .dropdown-btn
        height inherit
        background transparent

        &:hover, &:focus
            color #fff

        &:active
            outline none

    .dropdown-toggle
        .dropdown-arrow
            margin-left 0
            margin-right 8px

        .count-items
            margin-right 5px

        .title
            margin-right 5px

    .clear-items
        display inline-block
        width 15px
        height 15px
        background url('/assets/images/cross.svg') no-repeat
        background-size contain
        position relative
        top 3px

    .dropdown-overlay
        position absolute
        top 0
        left 0
        width 100%
        height 100%
        background rgba(0, 0, 0, 0.3)
        z-index 1

        .loader
            position absolute
            width 56px
            height 56px
            margin auto
            top 0
            left 0
            bottom 0
            right 0
            transform scale(0.6)

            .loader_circle
                position absolute
                background-color rgb(255, 255, 255)
                height 10px
                width 10px
                border-radius 5px
                animation-name f_fadeG
                animation-duration 1.2s
                animation-iteration-count infinite
                animation-direction normal

            #lrotate_01
                left 0
                top 22.5px
                animation-delay 0.45s

            #lrotate_02
                left 6.5px
                top 6.5px
                animation-delay 0.6s

            #lrotate_03
                left 22.5px
                top 0
                animation-delay 0.75s

            #lrotate_04
                right 6.5px
                top 6.5px
                animation-delay 0.9s

            #lrotate_05
                right 0
                top 22.5px
                animation-delay 1.05s

            #lrotate_06
                right 6.5px
                bottom 6.5px
                animation-delay 1.2s

            #lrotate_07
                left 22.5px
                bottom 0
                animation-delay 1.35s

            #lrotate_08
                left 6.5px
                bottom 6.5px
                animation-delay 1.5s

.multi-select-checkbox-component
    &.active
        box-shadow inset 0 0 4px rgba(0, 0, 0, 1)
        background-color rgba(32, 41, 50, 0.8)
</style>
